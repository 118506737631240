.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projects h1 {
  margin-bottom: 20px;
  font-size: 60px;
  margin-top: 40px;
}

.projects h2 {
  text-align: center;
  margin-bottom: 20px;
}

.projects-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center; 
  margin-bottom: 40px;
}

.project {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 10px #512bd4;
  text-align: center; 
  max-width: 90%;
}

.project h2 {
  margin-top: 10px;
}

.project-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.project-links a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.project-links a:hover {
  color: white;
}

.project-links a svg {
  margin-right: 5px;
}

.project-link {
  border: 5px solid #512bd4;
  padding: 8px;
  border-radius: 10px;
  transition: 0.3s;
  background-color: #512bd4;
}

.project-link:hover {
  border: 5px solid white;
}

.udemy-link {
  margin-left: 10px;
}
.site-link {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .projects h1 {
    margin-top: 20px;
  }

  .projects {
    padding: 20px;
  }
}

@media (min-width: 1800px) {
  .projects {
    margin: 0 20%;
  }
}