*{
  margin: 0;
  padding: 0;
} 

body{
  height: 100vh;
  background: #1b1a1c;
  color:white;
  font-family: 'Montserrat';
}

hr{
  border-top: 2px solid white;
  width: 99%;
}

html {
  scroll-behavior: smooth;
}

