.experience-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experiences-bottom-line{
    margin-top: 60px;
}

.experience-section h2{
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 60px;
    text-align: center;
}

@media (max-width: 767px) {
    .experience-section h2{
        margin-bottom: 10px;
        margin-top: 20px;
        font-size: 40px;
        text-align: center;
    }

    .experiences-bottom-line{
        margin-top: 40px;
    }
}