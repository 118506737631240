.experience-card {
  position: relative; /* This is necessary for positioning the logo */
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  border: 6px solid; /* Border color will be set dynamically */
  margin: 20px 20px;
}

.company-logo {
  width: 200px;
  height: auto; 
  background-color: white;
  margin: 0 20px 25px 0;
}

.experience-card-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.job-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.job-summary-item {
  margin-bottom: 10px;
}

.normal-text p {
  margin-bottom: 10px;
  font-weight: 500;
}

.job-title {
  font-weight: 700;
  font-size: 22px;
}

.summary-phrase {
  font-weight: 600;
  font-size: 18px;
}

.job-date,
.job-tech {
  font-weight: 700;
  font-size: 16px;
}

@media (max-width: 767px) {
  .experience-card {
    padding: 15px;
  }

  .experience-card-top {
    flex-direction: column;
    align-items: flex-start;
  }

  .company-logo {
    order: 1;
    margin-right: 0;
  }

  .job-section {
    order: 2;
  }

  .job-title {
    font-size: 20px;
  }

  .summary-phrase {
    font-size: 16px;
  }

  .job-date,
  .job-tech {
    font-size: 14px;
  }

  .normal-text p {
    font-size: 14px;
  }

  .experience-section{
    margin-bottom: 20px;
  }
}
